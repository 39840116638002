<script>
  import AvatarMonthEmployee from "../MonthEmployee/AvatarMonthEmployee.vue";
  export default {
    props: ["employee", "openVisorByReaction"],
    data() {
      return {};
    },
    components: {
      AvatarMonthEmployee,
      Likes: () => import("../reactions/likes/Likes.vue"),
      Views: () => import("../reactions/views/Views.vue"),
      Claps: () => import("../reactions/claps/Claps.vue"),
    },
    computed: {
      screen() {
        return this.$screen.width >= 1024;
      },
      name() {
        return this.employee.user.name.charAt(0).toUpperCase() + this.employee.user.name.slice(1);
      },
      lastName() {
        return this.employee.user.lastname.charAt(0).toUpperCase() + this.employee.user.lastname.slice(1);
      },
      show() {
        return this.$user.role === "superadmin";
      },
      dataReactions() {
        return {
          item: this.employee._id,
          component: "monthemployees",
        };
      },
    },
    methods: {
      closeModal() {
        this.$emit("close");
      },
    },
  };
</script>

<template>
  <div class="modalMonthEmployee" :style="`--background:url(${employee.monthEmployeeImg})`">
    <section class="modalMonthEmployee__back">
      <div class="modalMonthEmployee__arrowBack" @click="closeModal">
        <iconic name="goBack" />
        <p class="modalMonthEmployee__return">Volver</p>
      </div>
      <div class="modalMonthEmployee__actions" v-if="false">
        <iconic name="iconEdit" class="modalMonthEmployee__edit" />
        <iconic name="iconDelete" class="modalMonthEmployee__delete" />
      </div>
    </section>
    <div class="modalMonthEmployee__desktop">
      <div class="modalMonthEmployee__profile">
        <div class="modalMonthEmployee__employee" v-if="!screen">
          <p class="modalMonthEmployee__user">{{ name }} {{ lastName }}</p>
        </div>
        <img class="modalMonthEmployee__photo" :src="employee.monthEmployeeImg" alt="" />
        <div class="modalMonthEmployee__reactions"></div>
        <div class="modalMonthEmployee__likeCard">
          <Likes :likes="employee.likers" :showLikes="true" :payload="dataReactions" :openLikes="(data) => openVisorByReaction('likes', data)" />
          <Claps :claps="employee.clapers" :showClaps="true" :payload="dataReactions" :openClaps="(data) => openVisorByReaction('claps', data)" />
          <Views :views="employee.viewers" :showViews="true" :openViews="(data) => openVisorByReaction('views', data)" v-if="show" />
        </div>
      </div>
      <div class="modalMonthEmployee__info">
        <div class="modalMonthEmployee__employee" v-if="screen">
          <img class="modalMonthEmployee__image" v-if="!screen" :src="employee.user.picture" alt="alejandra" />
          <p class="modalMonthEmployee__user">{{ name }} {{ lastName }}</p>
        </div>
        <section class="modalMonthEmployee__area">
          <span class="modalMonthEmployee__name">{{ employee.area.name }}</span>
          <span class="modalMonthEmployee__age">{{ employee.yearsInCompany }}</span>
        </section>
        <section class="modalMonthEmployee__description">
          <p class="modalMonthEmployee__text">
            {{ employee.description }}
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .modalMonthEmployee {
    background: #fffffff5;
    height: 100vh;
    padding: 15px;
    &__back {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: $space-8;
      padding-right: $space-20;
      cursor: pointer;
      padding-top: 20px;
    }
    &__arrowBack {
      display: flex;
      justify-content: space-between;
      gap: $space-8;
    }
    &__return {
      font-family: $newFont_regular;
      font-size: 16px;
      color: #000000;
    }
    &__actions {
      display: flex;
      gap: 20px;
    }
    &__edit {
      font-size: 15px;
      cursor: pointer;
    }
    &__delete {
      font-size: 28px;
      cursor: pointer;
    }
    &__container {
      height: 450px;
      width: 100%;
      background-image: var(--background);
      object-fit: cover;
      background-position: center;
      border-radius: $radius-12;
      margin-bottom: $space-24;
      object-fit: contain;
    }
    &__employee {
      display: flex;
      align-items: center;
      position: absolute;
      top: 22px;
      gap: $space-16;
      padding-left: 10px;
      border-radius: $radius-20 $radius-20 0px 0px;
      background: linear-gradient(180deg, rgba(48, 48, 48, 0.54) 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 70px;
    }
    &__image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: $space-2 solid $primary-color;
    }
    &__user {
      color: $primary-color;
      font-family: $newFont;
      font-size: 30px;
      text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.25);
      padding-left: $space-20;
    }
    &__reactions {
      max-width: 450px;
      width: 100%;
    }
    &__info {
      width: 100%;
      max-width: 960px;
      margin-top: 20px;
    }
    &__area {
      margin-bottom: 25px;
    }
    &__photo {
      width: 100%;
      border-radius: 20px;
      max-width: 450px;
      margin-top: 20px;
      border-radius: 23.158px;
      border: 2px solid var(--Dorado, #91693a);
      box-shadow: 0px 1px 16px 0px rgba(189, 9, 9, 0.35);
    }
    &__name {
      background: #fef1f1;
      padding: $space-12;
      border-radius: 42px;
      color: $primary-color;
      font-family: $newFont_semiBold;
      text-transform: uppercase;
      font-size: 16px;
    }
    &__age {
      font-family: $newFont_regular;
      color: #343434;
      font-size: 17px;
      margin: $space-20;
    }
    &__description {
      display: flex;
      justify-content: center;
      text-align: left;
      align-items: center;
    }
    &__text {
      text-align: justify;
      font-family: $newFont_regular;
      font-size: 18px;
      color: #2a2a2a;
      line-height: normal;
      width: 100%;
      max-width: 800px;
    }
    &__profile {
      width: fit-content;
      margin: auto;
      position: relative;
    }
    &__likeCard {
      display: flex;
      gap: 10px;
    }
    @media (min-width: 768px) {
      &__info {
        width: 500px;
      }
      &__desktop {
        @include Flex(column);
        justify-content: center;
        gap: 10px;
      }
    }
    @media (min-width: 1024px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__back {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 990px;
      }
      &__desktop {
        flex-direction: row;
        position: relative;
        width: 100%;
        display: flex;
        gap: 24px;
        max-width: 1200px;
      }
      &__container {
        height: 454px;
        width: 454px;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(
              342.96deg,
              #91693a 1.71%,
              #a07a43 9.36%,
              #c8a65d 24.68%,
              #dfbf6c 32.34%,
              #b48a42 53.12%,
              #bb944c 59.68%,
              #d1b169 69.53%,
              #f3df96 82.66%,
              #f7e59c 84.84%,
              #e6d08c 87.03%,
              #c7aa6e 91.41%,
              #af8d57 95.78%,
              #9d7846 100.16%,
              #936b3c 103.44%,
              #906739 107.82%
            )
            border-box;
        border-radius: 10px;
        border: 2px solid transparent;
        box-shadow: 0px 1px 16px 0px #bd090959;
      }
      &__imageDesktop {
        width: 450px;
        height: 450px;
        object-fit: cover;
        border-radius: $radius-8;
        margin: 0 auto;
      }
      &__user {
        font-size: 80px;
        padding-left: 0px;
      }
      &__info {
        position: relative;
        transform: translate(0%, 10%);
        width: inherit;
      }
      &__reactions {
        width: 450px;
      }
      &__area {
        margin: 20px 0;
      }
      &__text {
        font-size: 20px;
        padding-right: 20px;
        line-height: normal;
      }
      &__employee {
        align-items: center;
        top: -110px;
        margin-bottom: 10px;
        background: inherit;
        padding-left: 0px;
      }
      &__description {
        justify-content: inherit;
      }
      &__name {
        font-size: 20px;
        padding: 10px 20px;
      }
      &__likeCard {
        position: absolute;
        left: 105%;
        bottom: 0px;
      }
    }
    @media screen and (min-width: 1920px) {
      &__back {
        max-width: 1277px;
      }
    }
    @media screen and (min-width: 1920px) {
      &__arrowBack {
        padding-left: 50px;
      }
    }

    @media (min-width: 1300px) and (max-width: 2560px) {
      width: 100%;
      padding: 0 10%;
    }
  }
</style>
